
















import { Vue, Component } from 'vue-property-decorator';
import { IntegrationCard } from '@/components/integration';
import { Integration } from '@/models';
import { IntegrationService } from '@/lib/services';
import { namespace } from 'vuex-class';

const sAuth = namespace('auth');

@Component({
  components: {
    IntegrationCard,
  },
})
export default class IntegrationsSettingsPage extends Vue {
  @sAuth.Getter('isAccountant') isAccountant: boolean;

  integrations: Integration[] = [];

  async mounted(): Promise<void> {
    if (this.isAccountant && !this.$route.query['ignore-accountant']) {
      await this.$router.push({ name: 'accountant-integrations' });
      return;
    }
    await this.refresh();
  }

  async refresh(): Promise<void> {
    this.integrations = await IntegrationService.all();
  }

  update(integrations: Integration[]): void {
    this.integrations = integrations;
  }
}
